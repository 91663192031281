<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div class="text-normal" v-else>
      <HeaderPanel
        :title="ticketDetail?.ticket?.name"
        placeholder="Search Subject"
        :hasFilter="false"
        :hasSearch="false"
      >
        <template
          v-slot:filter-title
          v-if="
            ticketDetail?.ticket.sla_status === 1 &&
            ticketDetail?.ticket.ticket_status_id === 1
          "
        >
          <span class="badge badge-new badge-pill">New</span>
        </template>
        <template v-slot:content-dropdown>
          <b-dropdown-item
            @click="updateActivityByAction(2)"
            v-if="[1, 2].includes(formStatus)"
          >
            <span>Close</span>
          </b-dropdown-item>
          <b-dropdown-item @click="updateActivityByAction(3)">
            <span>Delete</span>
          </b-dropdown-item>
        </template>
        <template #frontAction>
          <button
            @click="isShowActivity = !isShowActivity"
            type="button"
            class="btn button mr-2"
            :class="[
              { 'main-color btn-save': isShowActivity },
              { 'btn-mobile': !isShowActivity },
            ]"
          >
            <font-awesome-icon icon="history" class="icon" />
            {{ isShowActivity ? "Hide Activities" : "Show Activities" }}
          </button>
        </template>
      </HeaderPanel>

      <b-tabs class="mt-3">
        <b-tab title="Activity" active>
          <b-row class="no-gutters mt-3">
            <b-col
              ><div class="left-panel">
                <TicketDetail
                  :ticketDetail="ticketDetail"
                  :form="formDetail.form_field"
                />
                <ActivityList
                  :ticketDetail="ticketDetail"
                  :ticketId="ticketId"
                  :isShowActivity="isShowActivity"
                  @getDetail="getFormDetail"
                /></div
            ></b-col>
            <b-col sm="4" class="ml-2"
              ><div class="right-panel">
                <div>
                  <TicketCustomerDetail
                    v-if="userProfile"
                    :profile="userProfile"
                  />
                </div>
                <div class="activity-form" v-if="formDetail.form_field.length">
                  <div class="form">
                    <FormInputList
                      :items="formDetail.form_field || []"
                      :status="formDetail.ticket.ticket_status_id || 0"
                      :depth="0"
                      :errors="$v.formDetail.form_field"
                      :is-edit="true"
                      :currentPage="currentPage"
                      :rawAdmin="ticketDetail.ticket.assign_to"
                    />
                  </div>
                  <div class="pagination-wrapper" v-if="hasPage">
                    <div>{{ currentPage }} / {{ totalPage }}</div>
                    <div class="pagination-action">
                      <b-button
                        class="btn main-color btn-save w-100px"
                        :disabled="currentPage == 1"
                        @click="prevPage"
                      >
                        Previous</b-button
                      >

                      <b-button
                        class="btn main-color btn-save w-100px"
                        :disabled="currentPage == totalPage"
                        @click="nextPage"
                      >
                        Next</b-button
                      >
                    </div>
                  </div>
                  <div class="action py-2">
                    <b-button class="main-color btn-save" @click="sendForm">
                      Update
                    </b-button>
                  </div>
                </div>

                <RecentActivity
                  v-if="ticketDetail.ticket.user_guid && ticketId"
                  :userId="ticketDetail.ticket.user_guid"
                  :ticket-id="ticketId"
                /></div
            ></b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import FormInputList from "@/components/ticket/activity/FormInputList";
import RecentActivity from "@/components/ticket/activity/RecentActivity";
import { requiredIf } from "vuelidate/lib/validators";
import TicketCustomerDetail from "@/components/ticket/activity/TicketCustomerDetail";
import TicketDetail from "@/components/ticket/activity/TicketDetail";
import ActivityList from "@/components/ticket/activity/ActivityList";
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: {
    FormInputList,
    RecentActivity,
    TicketCustomerDetail,
    TicketDetail,
    ActivityList,
    OtherLoading,
  },
  data() {
    return {
      isShowActivity: false,
      isLoading: true,
      isSubmit: false,
      ticketId: 0,
      formActionType: "submit",
      currentPage: 1,
      formDetail: {
        ticket_answer: [],
        form_field: [],
        ticket: {
          activity_source_id: 0,
          assign_to: "",
          created_by: "",
          created_time: "",
          id: 0,
          name: "",
          sla_status: 0,
          ticket_detail: "",
          ticket_form_id: 0,
          ticket_status_id: 0,
          ticket_type_id: 0,
          updated_by: "",
          updated_time: "",
          user_guid: "",
        },
        user_detail: {
          user_guid: null,
          name: null,
          member_id: null,
          telephone: null,
          email: null,
          member_tier: null,
          register_date: "0001-01-01T00:00:00",
          consent_status: null,
          is_connect: 0,
          is_company: 0,
          picture: null,
        },
      },
      ticketDetail: {
        ticket_answer: [],
        form_field: [],
        ticket: {
          activity_source_id: 0,
          assign_to: "",
          created_by: "",
          created_time: "",
          id: 0,
          name: "",
          sla_status: 0,
          ticket_detail: "",
          ticket_form_id: 0,
          ticket_status_id: 0,
          ticket_type_id: 0,
          updated_by: "",
          updated_time: "",
          user_guid: "",
        },
        user_detail: {
          user_guid: null,
          name: null,
          member_id: null,
          telephone: null,
          email: null,
          member_tier: null,
          register_date: "0001-01-01T00:00:00",
          consent_status: null,
          is_connect: 0,
          is_company: 0,
          picture: null,
        },
      },
    };
  },
  computed: {
    userProfile() {
      return this.ticketDetail.form_field.find((el) => el.ticket_field_id == 1)
        ?.selectedValue;
    },
    formStatus() {
      return this.formDetail.form_field.find((el) => el.id == 623).value;
    },
    hasPage() {
      return this.formDetail.form_field.some((el) => el.field_type_id == 14);
    },
    totalPage() {
      return this.formDetail.form_field[this.formDetail.form_field.length - 1]
        .page;
    },
  },

  validations() {
    return {
      formDetail: {
        form_field: {
          $each: {
            value: {
              required: requiredIf(function (item) {
                const isSubmit =
                  this.formActionType === "submit" &&
                  Boolean(item.is_require_submit);
                const isClose =
                  (this.formActionType === "close" || this.formStatus == 4) &&
                  Boolean(item.is_require_close);
                return isSubmit || isClose;
              }),
            },
            field_dependent: {
              $each: {
                value: {
                  required: requiredIf(function (item) {
                    const isSubmit =
                      this.formActionType === "submit" &&
                      Boolean(item.is_require_submit) &&
                      item.isActive;
                    const isClose =
                      (this.formActionType === "close" ||
                        this.formStatus == 4) &&
                      Boolean(item.is_require_close) &&
                      item.isActive;
                    return isSubmit || isClose;
                  }),
                },
                field_dependent: {
                  $each: {
                    value: {
                      required: requiredIf(function (item) {
                        const isSubmit =
                          this.formActionType === "submit" &&
                          Boolean(item.is_require_submit) &&
                          item.isActive;
                        const isClose =
                          (this.formActionType === "close" ||
                            this.formStatus == 4) &&
                          Boolean(item.is_require_close) &&
                          item.isActive;
                        return isSubmit || isClose;
                      }),
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  async created() {
    this.ticketId = this.$route.params.ticketId;
    if (this.ticketId) this.getFormDetail();
  },
  methods: {
    nextPage() {
      this.currentPage += 1;
      this.$forceUpdate();
    },
    prevPage() {
      this.currentPage -= 1;
      this.$forceUpdate();
    },
    async getFormDetail() {
      this.isLoading = true;

      const { data } = await this.axios(`/ticket/${this.ticketId}`);
      if (data.result === 1 && data.detail.form_field.length) {
        data.detail.form_field.forEach((element) => {
          element.field_dependent = this.mapFieldFromDepth(
            element.field_dependent,
            element.id
          );
        });
        this.formDetail = data.detail;
        this.formDetail.form_field = await this.mappingValue(
          data.detail.form_field
        );
        this.ticketDetail = { ...JSON.parse(JSON.stringify(this.formDetail)) };
      }
      this.isLoading = false;
    },
    mapFieldFromDepth(fields, parentId = null) {
      if (!fields?.length) return [];
      return fields
        .filter((el) => el.parent_id === parentId)
        .map((el) => {
          return {
            ...el,
            field_dependent: this.mapFieldFromDepth(
              fields,
              el.ticket_form_field_id
            ),
          };
        });
    },

    async mappingValue(items, page = 1) {
      if (!items.length) return;
      const promises = items.map(async (el, index) => {
        if (items[index - 1] && items[index - 1].field_type_id == 14) {
          page = await (page + 1);
        }

        if (el.field_dependent?.length) {
          el.field_dependent = await this.mappingValue(
            el.field_dependent,
            page
          );
        }

        const target = await this.formDetail.ticket_answer.find(
          (ans) =>
            ans.ticket_form_field_id === (el.ticket_form_field_id || el.id)
        );

        let value = target?.value;
        let displayValue = "";
        let selectedValue = null;

        if (el.ticket_field_id === 7 && value) {
          const admin = await this.getAdminDetail(value);
          if (admin) {
            displayValue = admin.displayValue;
            selectedValue = {
              ...admin.selectedValue,
            };
          }
        } else if (el.ticket_field_id === 1 && value) {
          const customer = this.formDetail.user_detail;
          if (customer) {
            displayValue = [customer?.name, customer?.telephone]
              .filter((el) => el)
              .join(", ");

            selectedValue = {
              ...this.formDetail.user_detail,
            };
          }
        } else if (el.field_type_id === 2) {
          value = value ? value.split(",") : [];
        } else if (el.field_type_id === 3 && value) {
          let defaultValue = value.split(",");
          value = el.field_choice.filter((el) =>
            defaultValue.includes(el.id.toString())
          );
        }

        return {
          ...el,
          value: value || null,
          displayValue,
          selectedValue,
          answerId: target?.id || 0,
          isActive: false,
          page: page,
        };
      });

      const allResults = await Promise.all(promises);
      return allResults;
    },

    flatFields(data) {
      return data
        .reduce((acc, x) => {
          acc = acc.concat(x);
          if (x.field_dependent) {
            acc = acc.concat(this.flatFields(x.field_dependent));
            x.field_dependent = [];
          }
          return acc;
        }, [])
        .map((el) => {
          let value = el.value;
          if (el.field_type_id == 2)
            value = el.value?.map((item) => item).join(",");
          else if (el.field_type_id == 3)
            value = el.value?.map((item) => item.id).join(",");
          return {
            id: el.answerId || 0,
            ticket_form_field_id: el.ticket_form_field_id || el.id,
            ticket_field_id: el.ticket_field_id,
            value,
            is_default_field: el.is_default_field,
            field_dependent: el.field_dependent?.length
              ? this.flatFields(el.field_dependent)
              : [],
          };
        });
    },

    async sendForm() {
      this.formActionType = "submit";
      this.$v.formDetail.$touch();
      if (this.$v.formDetail.$error) {
        return;
      }
      this.$bus.$emit("showLoading");

      const payload = {
        id: this.ticketId,
        ticket_form_id: 1,
        field_answer: this.flatFields(
          JSON.parse(JSON.stringify(this.formDetail.form_field))
        ),
      };

      const { data } = await this.axios.post(`/ticket`, payload);

      this.$bus.$emit("hideLoading");
      if (data.result == 1) {
        this.isLoading = false;

        this.successAlert().then(() => {
          this.getFormDetail();
        });
      } else {
        this.isLoading = false;
        this.errorAlert(data.message);
      }
    },

    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", parseInt(id));
      field.branchList = this.$store.state.coupon.branchList.detail;
      this.branchList = this.$store.state.coupon.branchList.detail;
    },

    async getCustomerDetail(id) {
      const payload = {
        search: "",
        page: 1,
        pageSize: 1,
        user_guid: id,
      };
      const { data } = await this.axios.post(
        `/ticket/filter/customer`,
        payload
      );
      if (data?.detail?.data) {
        const admin = data.detail.data[0];
        return {
          selectedValue: {
            ...admin,
          },
          displayValue: [admin?.name, admin?.telephone]
            .filter((el) => el)
            .join(", "),
        };
      }
      return null;
    },

    async getAdminDetail(id) {
      const payload = {
        search: "",
        page: 1,
        pageSize: 1,
        user_guid: id,
      };
      const { data } = await this.axios.post(`/ticket/filter/admin`, payload);
      if (data?.detail?.data) {
        const admin = data.detail.data[0];
        return {
          selectedValue: {
            ...admin,
          },
          displayValue: [admin?.name, admin?.telephone]
            .filter((el) => el)
            .join(", "),
        };
      }
      return null;
    },

    async updateActivityByAction(actionType) {
      this.formActionType = actionType == 2 ? "close" : "delete";
      if (actionType == 2) {
        this.$v.formDetail.$touch();
        if (this.$v.formDetail.$error) {
          return;
        }
      }

      this.$bus.$emit("showLoading");
      const tickets = [
        {
          ticket_id: this.ticketId,
          ticket_form_id: 1,
        },
      ];
      const payload = {
        tickets: tickets,
        assignTo: "",
        action_type: actionType, // 1 = assign to , 2 = delete
      };

      const { data } = await this.axios.post(`/ticket/action`, payload);

      this.$bus.$emit("hideLoading");
      if (data.result == 1) {
        this.successAlert().then(() => {
          if (actionType == 3) this.$router.push("/ticket-activity");
          else this.getFormDetail();
        });
      } else {
        this.errorAlert(data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .text-float-normal .input-custom {
  label {
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 100;
  }
}

.activity-form {
  background-color: white;
  padding: 16px;
  position: relative;
  border-radius: 0.75rem;

  .btn-save {
    width: 100%;
  }
}

.btn-save {
  width: auto;
}

#footer {
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  height: 10%;
  padding: 15px;
  background: #fff;
}

.badge-new {
  background-color: rgb(39, 189, 167);
  color: white;
  padding: 0.4em 0.6em;
}

::v-deep .header-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

::v-deep .header-tablepage {
  margin: 0 !important;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pagination-action {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.w-100px {
  width: 101px !important;
  min-width: 0 !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
